import React, { useState, useEffect, useCallback, useRef } from 'react';

// Mapping from semitone numbers to note names
const SEMITONE_TO_NOTE = {
  0: 'C',
  1: 'C#',
  2: 'D',
  3: 'D#',
  4: 'E',
  5: 'F',
  6: 'F#',
  7: 'G',
  8: 'G#',
  9: 'A',
  10: 'A#',
  11: 'B',
};

const ArpeggioGenerator = () => {
  // Audio States
  const [audioContext, setAudioContext] = useState(null);
  const [masterGainNode, setMasterGainNode] = useState(null);
  const [melodyBuffer, setMelodyBuffer] = useState(null);
  const [accompanimentBuffer, setAccompanimentBuffer] = useState(null);

  // Note States
  const [melodyNotes, setMelodyNotes] = useState([0, 0, 7, 7, 9, 9, 7, null, 5, 5, 4, 4, 2, 2, 0, null]);
  const [accompanimentNotes, setAccompanimentNotes] = useState([0, 4, 7, null]);
  const [activeNotes, setActiveNotes] = useState([]);

  // Control States
  const [isPlaying, setIsPlaying] = useState(false);
  const [delay, setDelay] = useState(250);
  const [speed, setSpeed] = useState(1.0);
  const [volume, setVolume] = useState(0.5);
  const [repetitions, setRepetitions] = useState(1);
  const [playbackMode, setPlaybackMode] = useState('simultaneous');
  const [pianoMode, setPianoMode] = useState('melody');
  const [exportInProgress, setExportInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Refs for cleanup
  const activeSourcesRef = useRef({});
  const stopPlaybackTimeoutRef = useRef(null);

  // Initialize Audio Context
  useEffect(() => {
    const initAudio = async () => {
      try {
        const context = new (window.AudioContext || window.webkitAudioContext)({
          sampleRate: 48000,
          latencyHint: 'interactive',
        });

        const gainNode = context.createGain();
        gainNode.connect(context.destination);
        gainNode.gain.setValueAtTime(volume, context.currentTime);

        setAudioContext(context);
        setMasterGainNode(gainNode);
      } catch (error) {
        console.error('Audio initialization error:', error);
        setErrorMessage('Failed to initialize audio system.');
      }
    };

    initAudio();

    return () => {
      stopAllAudio();
      if (audioContext) {
        audioContext.close();
      }
    };
  }, []);

  // Update master gain when volume changes
  useEffect(() => {
    if (masterGainNode && audioContext) {
      masterGainNode.gain.setValueAtTime(volume, audioContext.currentTime);
    }
  }, [volume, masterGainNode, audioContext]);

  // Audio File Loading
  const loadAudioFile = useCallback(async (file, setBuffer) => {
    if (!audioContext || !file) return;

    try {
      const arrayBuffer = await file.arrayBuffer();
      const decodedBuffer = await audioContext.decodeAudioData(arrayBuffer);
      setBuffer(decodedBuffer);
      setErrorMessage('');
    } catch (error) {
      console.error('Error loading audio file:', error);
      setErrorMessage('Error loading audio file. Please try another file.');
    }
  }, [audioContext]);
   const createNote = useCallback((buffer, semitone, startTime, duration, velocity = 1) => {
    if (!audioContext || !buffer || semitone === null || !masterGainNode) return null;

    const source = audioContext.createBufferSource();
    const gainNode = audioContext.createGain();

    source.buffer = buffer;
    source.playbackRate.value = Math.pow(2, semitone / 12) * speed;

    // Improved envelope for cleaner sound
    gainNode.gain.setValueAtTime(0, startTime);
    gainNode.gain.linearRampToValueAtTime(velocity * volume, startTime + 0.005);
    gainNode.gain.setValueAtTime(velocity * volume, startTime + duration - 0.005);
    gainNode.gain.linearRampToValueAtTime(0, startTime + duration);

    source.connect(gainNode);
    gainNode.connect(masterGainNode);

    const sourceId = Date.now() + Math.random();
    activeSourcesRef.current[sourceId] = { source, gainNode, semitone };

    source.onended = () => {
      delete activeSourcesRef.current[sourceId];
      setActiveNotes(prev => prev.filter(note => note !== semitone));
      source.disconnect();
      gainNode.disconnect();
    };

    source.start(startTime);
    source.stop(startTime + duration);
    setActiveNotes(prev => [...prev, semitone]);

    return sourceId;
  }, [audioContext, masterGainNode, speed, volume]);

  // Play Note Preview
  const playPreviewNote = useCallback((semitone) => {
    if (!audioContext || !melodyBuffer) return;
    createNote(melodyBuffer, semitone, audioContext.currentTime, 0.3, 0.8);
  }, [audioContext, melodyBuffer, createNote]);

  // Play Sequence
  const playSequence = useCallback((notes, buffer, startTime) => {
    if (!audioContext || !buffer) return;
    notes.forEach((note, index) => {
      if (note !== null) {
        const noteStartTime = startTime + index * (delay / 1000);
        createNote(buffer, note, noteStartTime, delay / 1000);
      }
    });
  }, [delay, createNote, audioContext]);

  // Play Arpeggios
  const playArpeggios = useCallback(() => {
    if (isPlaying || !melodyBuffer || !accompanimentBuffer) return;

    const startPlayback = async () => {
      if (audioContext.state === 'suspended') {
        await audioContext.resume();
      }

      setIsPlaying(true);
      const startTime = audioContext.currentTime;

      for (let i = 0; i < repetitions; i++) {
        if (playbackMode === 'simultaneous') {
          playSequence(melodyNotes, melodyBuffer, startTime + i * (melodyNotes.length * delay / 1000));
          playSequence(accompanimentNotes, accompanimentBuffer, startTime + i * (accompanimentNotes.length * delay / 1000));
        } else {
          const melodyDuration = melodyNotes.length * delay / 1000;
          playSequence(
            melodyNotes,
            melodyBuffer,
            startTime + i * (melodyDuration + accompanimentNotes.length * delay / 1000)
          );
          playSequence(
            accompanimentNotes,
            accompanimentBuffer,
            startTime + i * (melodyDuration + accompanimentNotes.length * delay / 1000) + melodyDuration
          );
        }
      }

      const totalDuration = playbackMode === 'simultaneous'
        ? repetitions * Math.max(melodyNotes.length, accompanimentNotes.length) * delay
        : repetitions * (melodyNotes.length + accompanimentNotes.length) * delay;

      stopPlaybackTimeoutRef.current = setTimeout(() => {
        setIsPlaying(false);
      }, totalDuration);
    };

    startPlayback();
  }, [
    isPlaying,
    melodyBuffer,
    accompanimentBuffer,
    melodyNotes,
    accompanimentNotes,
    delay,
    repetitions,
    playbackMode,
    playSequence,
    audioContext
  ]);

  // Enhanced Stop Function
  const stopAllAudio = useCallback(() => {
    // Clear all timeouts
    if (stopPlaybackTimeoutRef.current) {
      clearTimeout(stopPlaybackTimeoutRef.current);
      stopPlaybackTimeoutRef.current = null;
    }

    // Immediately fade out and stop all active sources
    const fadeOutTime = 0.05;
    const currentTime = audioContext?.currentTime || 0;

    Object.values(activeSourcesRef.current).forEach(({ source, gainNode }) => {
      gainNode.gain.cancelScheduledValues(currentTime);
      gainNode.gain.setValueAtTime(gainNode.gain.value, currentTime);
      gainNode.gain.linearRampToValueAtTime(0, currentTime + fadeOutTime);

      setTimeout(() => {
        source.stop();
        source.disconnect();
        gainNode.disconnect();
      }, fadeOutTime * 1000);
    });

    activeSourcesRef.current = {};
    setActiveNotes([]);
    setIsPlaying(false);
  }, [audioContext]);

  // Note Management
  const addNote = useCallback((type, note) => {
    if (type === 'melody') {
      setMelodyNotes(prev => [...prev, note]);
    } else {
      setAccompanimentNotes(prev => [...prev, note]);
    }
  }, []);

  const removeNote = useCallback((type, index) => {
    if (type === 'melody') {
      setMelodyNotes(prev => prev.filter((_, i) => i !== index));
    } else {
      setAccompanimentNotes(prev => prev.filter((_, i) => i !== index));
    }
  }, []);

  // WAV Export Utilities
  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  const floatTo16BitPCM = (view, offset, data) => {
    for (let i = 0; i < data.length; i++, offset += 2) {
      const s = Math.max(-1, Math.min(1, data[i]));
      view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
  };

  const audioBufferToWav = (audioBuffer) => {
    const numChannels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length * numChannels;
    const sampleRate = audioBuffer.sampleRate;
    const data = new Float32Array(length);

    // Interleave channels
    for (let channel = 0; channel < numChannels; channel++) {
      const channelData = audioBuffer.getChannelData(channel);
      for (let i = 0; i < audioBuffer.length; i++) {
        data[i * numChannels + channel] = channelData[i];
      }
    }

    const wavBuffer = new ArrayBuffer(44 + data.length * 2);
    const view = new DataView(wavBuffer);

    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + data.length * 2, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * numChannels * 2, true);
    view.setUint16(32, numChannels * 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, data.length * 2, true);
    floatTo16BitPCM(view, 44, data);

    return wavBuffer;
  };
    const handleExport = useCallback(async () => {
    if (!melodyBuffer || !accompanimentBuffer || exportInProgress) return;

    setExportInProgress(true);
    try {
      const totalDuration = playbackMode === 'simultaneous'
        ? Math.max(melodyNotes.length, accompanimentNotes.length) * delay / 1000 * repetitions
        : (melodyNotes.length + accompanimentNotes.length) * delay / 1000 * repetitions;

      const offlineCtx = new OfflineAudioContext(2, Math.ceil(totalDuration * 48000), 48000);
      const offlineGain = offlineCtx.createGain();
      offlineGain.gain.setValueAtTime(volume, 0);
      offlineGain.connect(offlineCtx.destination);

      const renderSequence = (notes, buffer, startTime) => {
        notes.forEach((note, index) => {
          if (note !== null) {
            const source = offlineCtx.createBufferSource();
            const gainNode = offlineCtx.createGain();

            source.buffer = buffer;
            source.playbackRate.value = Math.pow(2, note / 12) * speed;

            const noteStartTime = startTime + index * (delay / 1000);
            gainNode.gain.setValueAtTime(0, noteStartTime);
            gainNode.gain.linearRampToValueAtTime(volume, noteStartTime + 0.005);
            gainNode.gain.setValueAtTime(volume, noteStartTime + delay/1000 - 0.005);
            gainNode.gain.linearRampToValueAtTime(0, noteStartTime + delay/1000);

            source.connect(gainNode);
            gainNode.connect(offlineGain);
            source.start(noteStartTime);
            source.stop(noteStartTime + delay/1000);
          }
        });
      };

      // Render all sequences
      for (let i = 0; i < repetitions; i++) {
        if (playbackMode === 'simultaneous') {
          renderSequence(melodyNotes, melodyBuffer, i * (melodyNotes.length * delay / 1000));
          renderSequence(accompanimentNotes, accompanimentBuffer, i * (accompanimentNotes.length * delay / 1000));
        } else {
          const melodyDuration = melodyNotes.length * delay / 1000;
          renderSequence(melodyNotes, melodyBuffer, i * (melodyDuration + accompanimentNotes.length * delay / 1000));
          renderSequence(
            accompanimentNotes,
            accompanimentBuffer,
            i * (melodyDuration + accompanimentNotes.length * delay / 1000) + melodyDuration
          );
        }
      }

      // Render and download
      const renderedBuffer = await offlineCtx.startRendering();
      const wav = audioBufferToWav(renderedBuffer);
      const blob = new Blob([wav], { type: 'audio/wav' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'arpeggio.wav';
      link.click();

      // Cleanup
      URL.revokeObjectURL(url);
      setExportInProgress(false);
    } catch (error) {
      console.error('Export failed:', error);
      setErrorMessage('Failed to export audio. Please try again.');
      setExportInProgress(false);
    }
  }, [
    melodyBuffer,
    accompanimentBuffer,
    melodyNotes,
    accompanimentNotes,
    delay,
    speed,
    volume,
    repetitions,
    playbackMode,
    exportInProgress
  ]);

  // Component Render
  return (
    <div className="arpeggio-generator">
      <h1>Arpeggio Generator</h1>
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <div className="main-content">
        {/* File Upload Section */}
        <div className="file-upload-section">
          <div className="upload-container">
            <label className="file-label">
              <span>Melody Audio File (WAV/MP3)</span>
              <input
                type="file"
                accept=".wav,.mp3"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    loadAudioFile(file, setMelodyBuffer);
                  }
                }}
                className="file-input"
              />
              <div className="file-button">Choose File</div>
            </label>
          </div>

          <div className="upload-container">
            <label className="file-label">
              <span>Accompaniment Audio File (WAV/MP3)</span>
              <input
                type="file"
                accept=".wav,.mp3"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    loadAudioFile(file, setAccompanimentBuffer);
                  }
                }}
                className="file-input"
              />
              <div className="file-button">Choose File</div>
            </label>
          </div>
        </div>

        {/* Note Sequences Display */}
        <div className="sequences-section">
          <div className="sequence-display">
            <h3>Melody Sequence</h3>
            <div className="note-list">
              {melodyNotes.map((note, index) => (
                <div key={index} className="note-item">
                  <span>{note !== null ? SEMITONE_TO_NOTE[note] : 'Rest'}</span>
                  <button
                    onClick={() => removeNote('melody', index)}
                    className="remove-note"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
            <div className="add-note-group">
              <select id="melody-note-select">
                <option value="">Select Note</option>
                {Object.entries(SEMITONE_TO_NOTE).map(([semitone, name]) => (
                  <option key={semitone} value={semitone}>
                    {name} ({semitone})
                  </option>
                ))}
                <option value="null">Rest</option>
              </select>
              <button onClick={() => {
                const select = document.getElementById('melody-note-select');
                const value = select.value === 'null' ? null : parseInt(select.value);
                if (value !== '') {
                  addNote('melody', value);
                  select.value = '';
                }
              }}>
                Add Note
              </button>
            </div>
          </div>

          <div className="sequence-display">
            <h3>Accompaniment Sequence</h3>
            <div className="note-list">
              {accompanimentNotes.map((note, index) => (
                <div key={index} className="note-item">
                  <span>{note !== null ? SEMITONE_TO_NOTE[note] : 'Rest'}</span>
                  <button
                    onClick={() => removeNote('accompaniment', index)}
                    className="remove-note"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
            <div className="add-note-group">
              <select id="accompaniment-note-select">
                <option value="">Select Note</option>
                {Object.entries(SEMITONE_TO_NOTE).map(([semitone, name]) => (
                  <option key={semitone} value={semitone}>
                    {name} ({semitone})
                  </option>
                ))}
                <option value="null">Rest</option>
              </select>
              <button onClick={() => {
                const select = document.getElementById('accompaniment-note-select');
                const value = select.value === 'null' ? null : parseInt(select.value);
                if (value !== '') {
                  addNote('accompaniment', value);
                  select.value = '';
                }
              }}>
                Add Note
              </button>
            </div>
          </div>
        </div>

        {/* Controls Section */}
        <div className="controls-section">
          <div className="control-group">
            <label>
              <span>Delay: {delay}ms</span>
              <input
                type="range"
                min="50"
                max="500"
                value={delay}
                onChange={(e) => setDelay(Number(e.target.value))}
                className="slider"
              />
            </label>
          </div>

          <div className="control-group">
            <label>
              <span>Speed: {speed.toFixed(2)}x</span>
              <input
                type="range"
                min="0.5"
                max="2"
                step="0.1"
                value={speed}
                onChange={(e) => setSpeed(Number(e.target.value))}
                className="slider"
              />
            </label>
          </div>

          <div className="control-group">
            <label>
              <span>Volume: {Math.round(volume * 100)}%</span>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={(e) => setVolume(Number(e.target.value))}
                className="slider"
              />
            </label>
          </div>

          <div className="control-group">
            <label>
              <span>Repetitions: {repetitions}</span>
              <input
                type="range"
                min="1"
                max="10"
                value={repetitions}
                onChange={(e) => setRepetitions(Number(e.target.value))}
                className="slider"
              />
            </label>
          </div>

          <div className="control-group">
            <label>
              <span>Playback Mode</span>
              <select
                value={playbackMode}
                onChange={(e) => setPlaybackMode(e.target.value)}
                className="select-input"
              >
                <option value="simultaneous">Simultaneous</option>
                <option value="sequential">Sequential</option>
              </select>
            </label>
          </div>
        </div>
          {/* Piano Keyboard */}
        <div className="piano-section">
          <div className="piano-mode-toggles">
            <label className={`mode-toggle ${pianoMode === 'melody' ? 'active' : ''}`}>
              <input
                type="radio"
                name="pianoMode"
                value="melody"
                checked={pianoMode === 'melody'}
                onChange={(e) => setPianoMode(e.target.value)}
              />
              Melody
            </label>
            <label className={`mode-toggle ${pianoMode === 'accompaniment' ? 'active' : ''}`}>
              <input
                type="radio"
                name="pianoMode"
                value="accompaniment"
                checked={pianoMode === 'accompaniment'}
                onChange={(e) => setPianoMode(e.target.value)}
              />
              Accompaniment
            </label>
          </div>

          <div className="piano">
            <div className="white-keys">
              {[0, 2, 4, 5, 7, 9, 11].map((note) => (
                <div
                  key={note}
                  className={`key white ${activeNotes.includes(note) ? 'active' : ''}`}
                  onClick={() => {
                    playPreviewNote(note);
                    addNote(pianoMode, note);
                  }}
                >
                  <span className="note-name">{SEMITONE_TO_NOTE[note]}</span>
                </div>
              ))}
            </div>
            <div className="black-keys">
              {[1, 3, null, 6, 8, 10].map((note, index) => (
                note === null ? (
                  <div key="spacer" className="spacer" />
                ) : (
                  <div
                    key={note}
                    className={`key black ${activeNotes.includes(note) ? 'active' : ''}`}
                    onClick={() => {
                      playPreviewNote(note);
                      addNote(pianoMode, note);
                    }}
                  >
                    <span className="note-name">{SEMITONE_TO_NOTE[note]}</span>
                  </div>
                )
              ))}
            </div>
          </div>
        </div>

        {/* Control Buttons */}
        <div className="button-section">
          <button
            className={`control-button play ${isPlaying ? 'playing' : ''}`}
            onClick={playArpeggios}
            disabled={isPlaying || !melodyBuffer || !accompanimentBuffer}
          >
            {isPlaying ? 'Playing...' : 'Play Arpeggio'}
          </button>

          <button
            className="control-button stop"
            onClick={stopAllAudio}
            disabled={!isPlaying}
          >
            Stop
          </button>

          <button
            className="control-button export"
            onClick={handleExport}
            disabled={!melodyBuffer || !accompanimentBuffer || exportInProgress}
          >
            {exportInProgress ? 'Exporting...' : 'Export WAV'}
          </button>
        </div>
      </div>

      <style>
        {`
          .arpeggio-generator {
            max-width: 1200px;
            margin: 0 auto;
            padding: 2rem;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
          }

          .error-message {
            background: #fff3f3;
            color: #ff3b30;
            padding: 1rem;
            border-radius: 4px;
            margin: 1rem 0;
            border-left: 4px solid #ff3b30;
          }

          .file-upload-section {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 1rem;
            margin-bottom: 2rem;
          }

          .file-label {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          }

          .file-input {
            display: none;
          }

          .file-button {
            padding: 0.5rem 1rem;
            background: #f0f0f0;
            border: 1px solid #ddd;
            border-radius: 4px;
            text-align: center;
            cursor: pointer;
            transition: background-color 0.2s;
          }

          .file-button:hover {
            background: #e0e0e0;
          }

          .sequences-section {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 1.5rem;
            margin: 2rem 0;
          }

          .sequence-display {
            padding: 1rem;
            border: 1px solid #ddd;
            border-radius: 4px;
          }

          .note-list {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            margin-top: 1rem;
          }

          .note-item {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.25rem 0.5rem;
            background: #f0f0f0;
            border-radius: 4px;
          }

          .add-note-group {
            margin-top: 1rem;
            display: flex;
            gap: 0.5rem;
          }

          .add-note-group select {
            flex: 1;
            padding: 0.5rem;
            border: 1px solid #ddd;
            border-radius: 4px;
          }

          .add-note-group button {
            padding: 0.5rem 1rem;
            background: #007aff;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }

          .remove-note {
            border: none;
            background: none;
            color: #999;
            cursor: pointer;
            font-size: 1.2rem;
            padding: 0;
            line-height: 1;
          }

          .remove-note:hover {
            color: #ff3b30;
          }

          .piano-section {
            margin: 2rem 0;
          }

          .piano-mode-toggles {
            display: flex;
            justify-content: center;
            gap: 1rem;
            margin-bottom: 1rem;
          }

          .mode-toggle {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem 1rem;
            background: #f0f0f0;
            border-radius: 4px;
            cursor: pointer;
          }

          .mode-toggle.active {
            background: #007aff;
            color: white;
          }

          .mode-toggle input {
            display: none;
          }

          .piano {
            position: relative;
            height: 200px;
            background: #333;
            padding: 20px;
            border-radius: 8px;
            margin: 0 auto;
            width: fit-content;
          }

          .white-keys {
            display: flex;
            position: relative;
            z-index: 1;
          }

          .black-keys {
            position: absolute;
            display: flex;
            z-index: 2;
            left: 35px;
            top: 20px;
          }

          .key {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding-bottom: 10px;
            cursor: pointer;
            user-select: none;
            transition: all 0.1s;
          }

          .key.white {
            background: white;
            width: 50px;
            height: 180px;
            border: 1px solid #ccc;
            border-radius: 0 0 4px 4px;
            margin: 0 2px;
          }

          .key.black {
            background: #000;
            width: 30px;
            height: 120px;
            margin: 0 10px;
            border-radius: 0 0 3px 3px;
            color: white;
          }

          .key.white.active {
            background: #87CEFA;
          }

          .key.black.active {
            background: #4682B4;
          }

          .spacer {
            width: 30px;
            height: 120px;
            margin: 0 10px;
            visibility: hidden;
          }

          .button-section {
            display: flex;
            justify-content: center;
            gap: 1rem;
            margin-top: 2rem;
          }

          .control-button {
            padding: 0.8rem 1.5rem;
            border: none;
            border-radius: 4px;
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;
            min-width: 120px;
          }

          .control-button:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

          .control-button.play {
            background: #34c759;
            color: white;
          }

          .control-button.stop {
            background: #ff3b30;
            color: white;
          }

          .control-button.export {
            background: #007aff;
            color: white;
          }

          @media (max-width: 768px) {
            .arpeggio-generator {
              padding: 1rem;
            }

            .sequences-section,
            .controls-section {
              grid-template-columns: 1fr;
            }

            .button-section {
              flex-direction: column;
            }

            .control-button {
              width: 100%;
            }

            .piano {
              overflow-x: auto;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ArpeggioGenerator;